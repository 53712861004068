import api from '@/lib/api';
import Vue from 'vue';

const state = {
    selectedChat: null,
    chats: [],
    chatStatus: 'loading',
    connectedParticipants: {},
    audioElement: null,
    isMakingCall: false,
};

const getters = {
    areChatsLoaded: (state) => state.chatStatus === 'success',
    isAudioElementSetup: (state) => !!state.audioElement,
};

const actions = {
    chatsRequest: ({ commit }, { id = null, type = null }) => {
        commit('chatsRequest');

        const request = api.v2.chats.participantChats(id, type);
        request
            .then((resp) => {
                commit('chatSuccess', resp.data.data);
            })
            .catch(() => {
                commit('chatError');
            });

        return request;
    },
    adminChatsRequest: ({ commit }, { facilityId = null, keyword = null }) => {
        commit('chatsRequest');

        const request = api.admin.facilities.chatKeywordSearch(
            facilityId,
            keyword
        );
        request
            .then((resp) => {
                commit('chatSuccess', resp.data.data);
            })
            .catch(() => {
                commit('chatError');
            });

        return request;
    },
    adminParticipantChatsRequest: ({ commit }, participantId) => {
        commit('chatsRequest');

        const request = api.admin.chats.adminParticipantChats(participantId);
        request
            .then((resp) => {
                commit('chatSuccess', resp.data.data);
            })
            .catch(() => {
                commit('chatError');
            });

        return request;
    },
    // NOTE that this is using the admin API.
    showChatRequest: ({ commit }, chatId) => {
        commit('chatsRequest');

        const request = api.admin.chats.show(chatId);
        request
            .then(() => {
                commit('chatCrudSuccess');
            })
            .catch(() => {
                commit('chatError');
            });

        return request;
    },
    addChatRequest: ({ commit }, data) => {
        commit('chatsRequest');

        const request = api.v2.chats.store(data);
        request
            .then(() => {
                commit('chatCrudSuccess');
            })
            .catch(() => {
                commit('chatError');
            });

        return request;
    },
};

const mutations = {
    chatsRequest: (state) => {
        state.chatStatus = 'loading';
    },
    chatSuccess: (state, resp) => {
        state.chatStatus = 'success';
        state.chats = resp;
    },
    chatCrudSuccess: (state) => {
        state.chatStatus = 'success';
    },
    chatError: (state) => {
        state.chatStatus = 'error';
    },
    updateChat: (state, chat) => {
        state.selectedChat = chat;
    },
    updateAllChats: (state, chats) => {
        state.chatStatus = 'success';
        state.chats = chats;
    },
    updateConnectedParticipants: (state, data) => {
        Vue.set(state.connectedParticipants, 'chat_' + data.chatId, data.users);
    },
    addConnectedParticipant: (state, data) => {
        const users = state.connectedParticipants['chat_' + data.chatId];
        users.push(data.user);
        Vue.set(state.connectedParticipants, 'chat_' + data.chatId, users);
    },
    removeConnectedParticipant: (state, data) => {
        const users = state.connectedParticipants['chat_' + data.chatId];

        if (users && users.length) {
            const index = users.indexOf(data.user, 0);

            if (index >= 0) {
                users.splice(index, 1);
            }

            Vue.set(state.connectedParticipants, 'chat_' + data.chatId, users);
        }
    },
    reset: (state) => {
        state.selectedChat = null;
        state.chats = [];
        state.chatStatus = 'loading';
        state.connectedParticipants = {};
        state.isMakingCall = false;
    },
    updateAudioElement: (state, element) => {
        state.audioElement = element;
    },
    updateIsMakingCall: (state, value) => {
        state.isMakingCall = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
