import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store';
import router from '@/router';

axios.defaults.withCredentials = true;

const http = (window.http = axios.create({
    baseURL: process.env.VUE_APP_API_URL + 'api/v2',
}));
const isInmate =
    new URLSearchParams(window.location.search).has('inmate_id') ||
    Cookies.get('inmate_access_token');
const isAdmin =
    new URLSearchParams(window.location.search).has('chat') ||
    new URLSearchParams(window.location.search).has('keyword') ||
    new URLSearchParams(window.location.search).has('participant');
const bearer = isInmate
    ? Cookies.get('inmate_access_token')
    : isAdmin
    ? Cookies.get('admin_access_token')
    : Cookies.get('access_token');

if (bearer) {
    http.defaults.headers.common.Authorization = bearer;
}

http.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

if (isInmate) {
    // TODO(Call window.messagingApp.logout(), which knocks them back to login activity
    //      or perhaps even MainActivity, given their session has expired)
} else {
    const isMessagingApp =
        window.location.pathname.indexOf('iwebvisit-touch') > -1;
    // Handle an Unauthenticated API response, log the user out of the application.
    http.interceptors.response.use(
        (resp) => resp,
        (error) => {
            if (error.response && error.response.status === 401) {
                if (!isInmate && !isAdmin) {
                    store.commit('Auth/authLogout');
                }

                // TODO(If an admin viewing chat message/keyword search, should we route them
                //      when unauthenticated? Or maybe just show auth message and tell to go back???)
                if (
                    isMessagingApp &&
                    !isAdmin &&
                    router.currentRoute.path !== '/iwebvisit-touch'
                ) {
                    router.push('/iwebvisit-touch');
                } else if (router.currentRoute.path !== '/') {
                    router.push('/');
                }
            } else {
                console.error('oops', error);
            }
            return Promise.reject(error);
        }
    );
}

export default http;
